<template>
	<div class="serviceArea">
		<div class="serviceAreaTitle">
			<div>
				<img src="../../assets/img/ProductsServices/wangluo-net.png" alt="">
				<h2>天地一体立体观测体系</h2>
			</div>
			<p>
				<span>卫星排查- 全域；低空详查- 区域地面核查- 局域；站点网络- 精准。</span>
				<span>数据集：水环境大数据集；大气环境大数据集；环境监察执法大数据集；重点源监控大数据集。</span>
			</p>
		</div>
		<div class="serviceAreaPic">
			<img src="../../assets/img/ProductsServices/天地一体立体观测体系.png" alt="">
		</div>
		<div class="coverTitle">
			<img src="../../assets/img/ProductsServices/shanchangzhuanye.png" alt="">
			<h2>覆盖领域</h2>
			<div class="coverButton active" @click="switchArea(1)">水务领域</div>
			<div class="coverButton" @click="switchArea(2)">农业领域</div>
			<div class="coverButton" @click="switchArea(3)">自然资源</div>
		</div>
		<div class="waterArea">
			<div class="water" v-for="(item,index) in productList" :key="index">
				<img :src="item.pic" alt="">
				<p>{{item.title}}</p>
			</div>
		</div>
	</div>
</template>
<script>
	import api from '../../services/api.js'
	export default {
		name: 'serviceArea',
		data() {
			return {
				productList: [],
			}
		},
		mounted() {
			this.getProductData(1)
		},
		methods: {
			getProductData(id) {
				let prams = {
					type: id,
					curPage: 1,
					pageSize: 10
				}
				this.$axios({
					url: api.productsList,
					method: 'post',
					params: prams
				}).then((data) => {
					data.data.forEach((item, index) => {
						item.pic = api.url + item.pic
					})
					this.productList = data.data
				})
			},
			switchArea(id) {
				let coverButton = document.querySelectorAll(".coverButton")
				coverButton.forEach((item, index) => {
					item.classList = "coverButton"
				})
				if (id == 1) {
					coverButton[0].classList = "coverButton active"
				} else if (id == 2) {
					coverButton[1].classList = "coverButton active"
				} else if (id == 3) {
					coverButton[2].classList = "coverButton active"
				}
				this.getProductData(id)
			}
		}
	}
</script>
<style scoped lang="scss">
	.serviceArea {
		.serviceAreaTitle {
			background-color: #FFFFFF;

			>div {
				display: flex;
				padding: 57px 0 14px 370px;
				font-size: 24px;
				color: #2D2E2E;
				font-weight: 800;
				align-items: center;

				img {
					width: 38px;
					height: 38px;
					margin-right: 12px;
				}
			}

			>p {
				padding-left: 370px;
				font-size: 16px;
				color: #7A7A7A;

				span {
					margin-right: 40px;
				}
			}
		}

		.serviceAreaPic {
			background-color: #FFFFFF;
			padding: 27px 0 41px 360px;

			img {
				width: 1200px;
				height: 580px;
			}
		}

		.coverTitle {
			display: flex;
			align-items: center;
			padding: 39px 0 27px 364px;

			img {
				width: 38px;
				height: 38px;
				margin-right: 12px;
			}

			h2 {
				font-size: 30px;
				color: #2D2E2E;
				font-weight: 800;
				margin-right: 632px;
			}

			div {
				width: 117px;
				height: 40px;
				background-color: #FFFFFF;
				border: 1px solid #DDDDDD;
				text-align: center;
				line-height: 40px;
				font-size: 16px;
				margin-right: 19px;
				cursor: pointer;
			}

			div:hover {
				color: #0067C7;
			}

			div:active {
				color: white;
				background: linear-gradient(45deg, #0067C7, #00C0FF);
			}

			.active {
				color: white;
				background: linear-gradient(45deg, #0067C7, #00C0FF);
			}
		}

		.waterArea {
			width: 1590px;
			padding-left: 360px;
			margin-bottom: 17px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.water {
				background-color: white;
				text-align: center;
				margin: 0 25px 25px 0;

				img {
					width: 220px;
					height: 182px;
				}

				p {
					height: 38px;
					line-height: 38px;
					font-size: 16px;
					color: #2D2E2E;
					font-weight: 400;
				}
			}
		}
	}
</style>
