<template>
	<div class="products">
		<top @openWindow="openWindow" @pageid="toOtherPage"></top>
		<div class="topPic">
			<img src="../../assets/img/ProductsServices/突破科技，启迪未来.png" alt="">
		</div>
		<div class="productsChoise" v-show="!showDetail">
			<p class="productsTitle" @mouseover="init(1)" @mouseout="out" @click="changePage(1)">服务领域</p>
			<p class="productsTitle" @mouseover="init(2)" @mouseout="out" @click="changePage(2)">技术领域</p>
			<p class="productsTitle" @mouseover="init(3)" @mouseout="out" @click="changePage(3)">解决方案</p>
		</div>
		<div class="productsBarBox">
			<div class="productsBar" v-show="!showDetail">
				<img src="../../assets/img/ProductsServices/矩形 23.png" alt="">
			</div>
			<div class="productsBar" v-show="!showDetail">
				<img src="../../assets/img/ProductsServices/矩形 23.png" alt="">
			</div>
			<div class="productsBar" v-show="!showDetail">
				<img src="../../assets/img/ProductsServices/矩形 23.png" alt="">
			</div>
		</div>
		<serviceArea v-if="serviceAreaShow"></serviceArea>
		<technicalField v-if="technicalFieldShow"></technicalField>
		<Solution v-if="SolutionShow" @toDetail="changeDetail"></Solution>
		<recruit v-if="showRecruit" @close="closeWindow"></recruit>
		<solutionDetail v-if="showDetail" :detail-data="detailData" @toPage="changePage" @pageid="toOtherPage"></solutionDetail>
		<bottom @open="openWindow"></bottom>
	</div>
</template>

<script>
	import api from '../../services/api.js'
	import top from "../home/top.vue"
	import bottom from "../home/bottom.vue"
	import recruit from "../recruitmentInformation/recruit.vue"
	import serviceArea from "./serviceArea.vue"
	import technicalField from "./technicalField.vue"
	import Solution from "./Solution.vue"
	import solutionDetail from "./solutionDetail.vue"
	export default {
		name: 'products',
		data() {
			return {
				showRecruit: false,
				serviceAreaShow: false,
				technicalFieldShow: false,
				SolutionShow: false,
				showDetail: false,
				pageid: 0,
				changeId: 0,
				detailData: {}
			}
		},
		components: {
			top,
			bottom,
			recruit,
			serviceArea,
			technicalField,
			Solution,
			solutionDetail
		},
		mounted() {
			this.changePage(1)
			if (this.$route.query.toDetail != undefined) {
				this.getProductsDetail(this.$route.query)
			}
			this.switchs()
		},
		updated() {
			window.scroll(0, 0);
		},
		beforeRouteLeave(to, from, next) {
			if(this.showDetail == true && this.changeId == 0){
				this.changePage(this.pageid)
				next(false);
			}else{
				next();
			}
		},
		methods: {
			init(id) {
				var productsTitle = document.querySelectorAll(".productsTitle")
				productsTitle.forEach((item, index) => {
					item.style.cssText = "color: #101212;"
				})
				if (id == 1) {
					productsTitle[0].style.cssText = "color: #0079D3;"
				} else if (id == 2) {
					productsTitle[1].style.cssText = "color: #0079D3;"
				} else if (id == 3) {
					productsTitle[2].style.cssText = "color: #0079D3;"
				}
			},
			out() {
				var productsTitle = document.querySelectorAll(".productsTitle")
				productsTitle.forEach((item, index) => {
					item.style.cssText = "color: #101212;"
				})
				if (this.pageid == 1) {
					productsTitle[0].style.cssText = "color: #0079D3;"
				} else if (this.pageid == 2) {
					productsTitle[1].style.cssText = "color: #0079D3;"
				} else if (this.pageid == 3) {
					productsTitle[2].style.cssText = "color: #0079D3;"
				}
			},
			getProductsDetail(data){
				let picList = []
				this.$axios({
					url: api.solutionDetail + data.id,
					method: 'get',
				}).then((data) => {
					if(data.data.product.contentImages){
						data.data.product.contentImages.forEach((item, index) => {
							item = api.url + item
							picList.push(item)
						})
						data.data.product.contentImages = picList
					}
					data.data.product.pic = api.url + data.data.product.pic
					data.data.product.pic2 = api.url + data.data.product.pic2
					data.data.product.prePic = api.url + data.data.product.prePic
					data.data.product.nextPic = api.url + data.data.product.nextPic
					this.detailData = data.data.product
					this.serviceAreaShow = false
					this.technicalFieldShow = false
					this.SolutionShow = false
					this.showDetail = true
				})
			},
			changeDetail(data) {
				this.serviceAreaShow = false
				this.technicalFieldShow = false
				this.SolutionShow = false
				this.showDetail = true
				this.detailData = data
			},
			toOtherPage(id){
				this.changeId = id
			},
			changePage(id) {
				this.pageid = id
				this.out()
				var productsBar = document.querySelectorAll(".productsBar")
				productsBar.forEach((item,index)=>{
					item.style.cssText = "visibility: hidden;"
				})
				if (id == 1) {
					this.serviceAreaShow = true
					this.technicalFieldShow = false
					this.SolutionShow = false
					this.showDetail = false
					productsBar[0].style.cssText = "visibility: visible;"
				} else if (id == 2) {
					this.serviceAreaShow = false
					this.technicalFieldShow = true
					this.SolutionShow = false
					this.showDetail = false
					productsBar[1].style.cssText = "visibility: visible;"
				} else if (id == 3) {
					this.serviceAreaShow = false
					this.technicalFieldShow = false
					this.SolutionShow = true
					this.showDetail = false
					productsBar[2].style.cssText = "visibility: visible;"
				}
			},
			openWindow() {
				this.showRecruit = true
			},
			closeWindow(data) {
				this.showRecruit = false
			},
			switchs(){
				let titleFont = document.querySelectorAll(".titleFont")
				let switchBar = document.querySelectorAll(".switchBar")
				titleFont.forEach((item, index) => {
					item.style.cssText = "color: #101212;"
				})
				switchBar.forEach((item,index)=>{
					item.style.cssText = " visibility: hidden;"
				})
				switchBar[1].style.cssText = " visibility: visible;"
				titleFont[1].style.cssText = "color: #0079D3;"
			}
		}
	}
</script>

<style scoped lang="scss">
	.products {
		background-color: #F8F8F8;

		.topPic {
			height: 380px;

			img {
				width: 1903px;
				height: 380px;
			}
		}

		.productsChoise {
			background-color: #FFFFFF;
			display: flex;
			padding-left: 360px;

			p {
				padding: 0 18px;
				margin: 21px 37px 10px 0;
				font-size: 16px;
				color: #101212;
				font-weight: 600;
				cursor: pointer;
			}
			p:active{
				color: #00396F !important;
			}
		}
		.productsBarBox{
			padding-left: 360px;
			background-color: #FFFFFF;
			display: flex;
			.productsBar {
				width: 138px;
				padding-left: 9px;
				visibility: hidden;
				img {
					width: 79px;
					height: 4px;
				}
			}
		}
	}
</style>
