<template>
	<div class="technicalField">
		<div class="technicTitle">
			<div>
				<img src="../../assets/img/ProductsServices/hexin.png" alt="">
				<h2>SuperSIT核心技术</h2>
			</div>
			<p>
				<span>卫星排查- 全域；低空详查- 区域地面核查- 局域；站点网络- 精准。</span>
				<span>数据集：水环境大数据集；大气环境大数据集；环境监察执法大数据集；重点源监控大数据集。</span>
			</p>
		</div>
		<div class="technicPic">
			<img src="../../assets/img/ProductsServices/核心技术.png" alt="">
		</div>
		<div class="technicTitle coreTitle">
			<div>
				<img src="../../assets/img/ProductsServices/kaifakongjianzhunruxuke.png" alt="">
				<h2>核心领域</h2>
			</div>
			<p>
				<span>核心领域包括四个模块：1、SuperSIT数据中心。2、SuperSIT时空信息云平台技术。3、SuperSIT时空大数据存储、融合及管理技术。4、三维显示技术。</span>
			</p>
		</div>
		<div class="technicPic coreTitle1">
			<img v-for="(item,index) in tecList" :key="index" :src="item.pic" alt="" v-show="item.showPic">
		</div>
		<div class="picButton">
			<div class="dataCenter active" @click="changePic(1)" v-if="tecList.length != 0">
				<img src="../../assets/img/ProductsServices/数据中心b.png" alt="" v-show="!tecList[0].showPic">
				<img src="../../assets/img/ProductsServices/数据中心w.png" alt="" v-show="tecList[0].showPic">
				<span>{{tecList[0].title}}</span>
			</div>
			<div class="dataCenter" @click="changePic(2)" v-if="tecList.length != 0">
				<img src="../../assets/img/ProductsServices/时空信息云平台技术b.png" alt="" v-show="!tecList[1].showPic">
				<img src="../../assets/img/ProductsServices/时空信息云平台技术w.png" alt="" v-show="tecList[1].showPic">
				<span v-if="tecList.length != 0">{{tecList[1].title}}</span>
			</div>
			<div class="dataCenter" @click="changePic(3)" v-if="tecList.length != 0">
				<img src="../../assets/img/ProductsServices/时空大数据存储、 融合及管理技术b.png" alt="" v-show="!tecList[2].showPic">
				<img src="../../assets/img/ProductsServices/时空大数据存储、 融合及管理技术w.png" alt="" v-show="tecList[2].showPic">
				<span v-if="tecList.length != 0">{{tecList[2].title}}</span>
			</div>
			<div class="dataCenter" @click="changePic(4)" v-if="tecList.length != 0">
				<img src="../../assets/img/ProductsServices/三维显示技术b.png" alt="" v-show="!tecList[3].showPic">
				<img src="../../assets/img/ProductsServices/三维显示技术w.png" alt="" v-show="tecList[3].showPic">
				<span v-if="tecList.length != 0">{{tecList[3].title}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import api from '../../services/api.js'
	export default {
		data() {
			return {
				tecList: [],
			}
		},
		mounted() {
			this.getProductData()
		},
		methods: {
			getProductData() {
				let prams = {
					type: 4,
					curPage: 1,
					pageSize: 10
				}
				this.$axios({
					url: api.productsList,
					method: 'post',
					params: prams
				}).then((data) => {
					data.data.forEach((item, index) => {
						item.pic = api.url + item.pic
						item["showPic"] = false
					})
					this.tecList = data.data
					this.changePic(1)
				})
			},
			changePic(id){
				var dataCenter = document.querySelectorAll(".dataCenter")
				dataCenter.forEach((item,index)=>{
					item.classList = "dataCenter"
				})
				this.tecList.forEach((item,index)=>{
					item.showPic = false
					if(id == (index + 1)){
						this.tecList[index].showPic = true
						dataCenter[index].classList = "dataCenter active"
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.technicalField {
		.technicTitle {
			background-color: #FFFFFF;

			>div {
				display: flex;
				padding: 57px 0 14px 368px;
				font-size: 24px;
				color: #2D2E2E;
				font-weight: 800;
				align-items: center;

				img {
					width: 38px;
					height: 38px;
					margin-right: 12px;
				}
			}

			>p {
				padding-left: 360px;
				font-size: 16px;
				color: #7A7A7A;

				span {
					margin-right: 40px;
				}
			}
		}

		.technicPic {
			background-color: #FFFFFF;
			padding: 27px 0 41px 360px;

			img {
				width: 1200px;
				height: 652px;
			}
		}

		.coreTitle {
			background-color: #F8F8F8 !important;
			padding-bottom: 0;
		}
		.coreTitle1 {
			background-color: #F8F8F8 !important;
			padding-bottom: 0;
			height: 675px;
			img{
				height: 648px;
			}
		}
		.picButton{
			padding-left: 360px;
			width: 1560px;
			display: flex;
			margin-bottom: 40px;
			.dataCenter{
				width: 300px;
				height: 79px;
				color: #0067C7;
				border: 1px solid #0067C7;
				font-size: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 16px;
				cursor: pointer;
				background-color: #F8F8F8;
				img{
					width: 22px;
					height: 22px;
					margin-right: 5px;
				}
			}
		}
		.active{
			color: white !important;
			background: linear-gradient(45deg, #0067C7, #00C0FF) !important;
		}
	}
</style>
