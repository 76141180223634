<template>
	<div class="Solution">
		<div class="solveTitle">
			<h2>SuperSIT优势领域</h2>
			<p>Company news center</p>
		</div>
		<div class="solveField">
			<div class="fieldBox" v-for="(item,index) in solutionList1" :key="index">
				<img :src="item.pic" alt="" @mouseover="hoverEvent(index)" @mouseout="outEvent(index)">
				<div class="RowBox" @mouseover="hoverEvent(index)" @mouseout="outEvent(index)">
					<div class="firstRow" @mouseover="hoverEvent(index)" @mouseout="outEvent(index)">
						<img style="height: 0.484375rem;" :style="index == 2 ? 'width: 0.484375rem' : 'width: 0.34375rem;'" :src="item.pic2" alt="">
					</div>
				</div>
				<h4 @click="toDetail(item.id)">{{item.title}}</h4>
				<p>{{item.subtitle}}</p>
				<p>{{item.brief}}</p>
				<div>
					<div @click="toDetail(item.id)">查看详情</div>
					<Icon type="logo-facebook" />
					<Icon type="logo-twitter" />
					<Icon type="ios-aperture-outline" />
					<Icon type="logo-linkedin" />
				</div>
			</div>
			<div class="fieldBox" v-for="(item,index) in solutionList2" :key="index">
				<img :src="item.pic" alt="" @mouseover="hoverEvent(index+3)" @mouseout="outEvent(index+3)">
				<div class="RowBox secondRowBox" @mouseover="hoverEvent(index+3)" @mouseout="outEvent(index+3)">
					<div class="firstRow" @mouseover="hoverEvent(index+3)" @mouseout="outEvent(index+3)">
						<img style="width: 0.484375rem;height: 0.46875rem;" :src="item.pic2" alt="">
					</div>
				</div>
				<h4 @click="toDetail(item.id)">{{item.title}}</h4>
				<p>{{item.subtitle}}</p>
				<p>{{item.brief}}</p>
				<div>
					<div @click="toDetail(item.id)">查看详情</div>
					<Icon type="logo-facebook" />
					<Icon type="logo-twitter" />
					<Icon type="ios-aperture-outline" />
					<Icon type="logo-linkedin" />
				</div>
			</div>
			<div class="fieldBox" v-for="(item,index) in solutionList3" :key="index">
				<img :src="item.pic" alt="" @mouseover="hoverEvent(index+6)" @mouseout="outEvent(index+6)">
				<div class="RowBox thirdRowBox" @mouseover="hoverEvent(index+6)" @mouseout="outEvent(index+6)">
					<div class="firstRow" @mouseover="hoverEvent(index+6)" @mouseout="outEvent(index+6)">
						<img style="width: 0.4635416rem;height: 0.453125rem;" :src="item.pic2" alt="">
					</div>
				</div>
				<h4 @click="toDetail(item.id)">{{item.title}}</h4>
				<p>{{item.subtitle}}</p>
				<p>{{item.brief}}</p>
				<div>
					<div @click="toDetail(item.id)">查看详情</div>
					<Icon type="logo-facebook" />
					<Icon type="logo-twitter" />
					<Icon type="ios-aperture-outline" />
					<Icon type="logo-linkedin" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from '../../services/api.js'
	export default {
		name: 'solution',
		data() {
			return {
				solutionList1: [],
				solutionList2: [],
				solutionList3: [],
			}
		},
		mounted() {
			this.getProductData()
		},
		methods: {
			getProductData() {
				this.solutionList1 = []
				this.solutionList2 = []
				this.solutionList3 = []
				let prams = {
					type: 5,
					curPage: 1,
					pageSize: 10
				}
				this.$axios({
					url: api.productsList,
					method: 'post',
					params: prams
				}).then((data) => {
					data.data.forEach((item, index) => {
						item.pic = api.url + item.pic
						item.pic2 = api.url + item.pic2
						if(index < 3){
							this.solutionList1.push(item)
						}else if(index < 6 && index >= 3){
							this.solutionList2.push(item)
						}else if(index < 9 && index >= 6){
							this.solutionList3.push(item)
						}
					})
				})
			},
			hoverEvent(id) {
				var firstRow = document.querySelectorAll(".firstRow")
				firstRow[id].style.cssText = "transform: translateY(0); transition: 0.5s;z-index: 2;"
			},
			outEvent(id) {
				var firstRow = document.querySelectorAll(".firstRow")
				firstRow[id].style.cssText = "transform: translateY(250px); transition: 0.5s;z-index: -1;"
			},
			toDetail(id){
				let params = {}
				let picList = []
				this.$axios({
					url: api.solutionDetail + id,
					method: 'get',
				}).then((data) => {
					if(data.data.product.contentImages){
						data.data.product.contentImages.forEach((item, index) => {
							item = api.url + item
							picList.push(item)
						})
						data.data.product.contentImages = picList
					}
					data.data.product.pic = api.url + data.data.product.pic
					data.data.product.pic2 = api.url + data.data.product.pic2
					data.data.product.prePic = api.url + data.data.product.prePic
					data.data.product.nextPic = api.url + data.data.product.nextPic
					params = data.data.product
					this.$emit('toDetail',params)
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.Solution {
		background-color: white;

		.solveTitle {
			width: 1903px;
			text-align: center;
			padding-top: 50px;

			h2 {
				font-size: 30px;
				color: #333333;
				font-weight: 600;
				padding-bottom: 16px;
			}

			p {
				padding-bottom: 23px;
				font-size: 14px;
				color: #959595;
			}
		}

		.solveField {
			width: 1600px;
			padding-left: 360px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.fieldBox {
				width: 380px;
				margin: 0 30px 43px 0;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);

				>img {
					width: 380px;
					height: 250px;
				}

				.RowBox {
					width: 380px;
					height: 253px;
					position: absolute;
					top: 690px;
					overflow: hidden;
				}

				.firstRow {
					width: 380px;
					height: 250px;
					position: absolute;
					text-align: center;
					line-height: 320px;
					background: rgba(0, 0, 0, 0.6);
					transform: translateY(250px);
					z-index: -1;
				}

				.secondRowBox {
					top: 1241px !important;
				}

				.thirdRowBox {
					top: 1793px !important;
				}

				h4 {
					width: 200px;
					position: relative;
					padding: 29px 0 12px 18px;
					font-size: 20px;
					color: #262626;
					z-index: 999;
				}
				h4:hover{
					cursor: pointer;
					color: #0067C7;
				}
				h4:active{
					cursor: pointer;
					color: #00396F;
				}
				p:first-of-type {
					padding: 0 0 19px 18px;
					font-size: 14px;
					color: #000000;
					opacity: 0.5;
					font-weight: 400;
				}

				p:nth-of-type(2) {
					width: 281px;
					height: 81px;
					font-size: 14px;
					color: #000000;
					opacity: 0.75;
					font-weight: 400;
					line-height: 24px;
					padding: 0 0 33px 18px;
				}

				>div:nth-of-type(2) {
					padding: 0 0 32px 18px;
					display: flex;
					align-items: center;

					>div {
						width: 120px;
						height: 30px;
						background: linear-gradient(45deg, #0067C7, #00C0FF);
						cursor: pointer;
						color: white;
						font-size: 14px;/*no*/
						font-weight: 400;
						text-align: center;
						line-height: 30px;
						margin-right: 132px;
					}
					>div:hover{
						color: white;
						background: linear-gradient(45deg, #558EC4, #6ADAFF);
					}
					>div:active{
						color: white;
						background: linear-gradient(45deg, #00509A, #008DBB);
					}
					i {
						font-size: 12px;
						margin-right: 17px;
					}
				}
			}
		}
	}
</style>
